import React from 'react';
import Layout from '../../../components/layout';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from "gatsby-image";
import { Helmet } from 'react-helmet';
import productsPageStyles from '../../../styles/productsPage.module.scss';
import data from '../../../data/fps-filters';
import Report1810081 from '../../resources/2018 10 08 CAF950R-FPS955 Report 181008-1.pdf'
import Report081113A from '../../resources/2008 11-13 Gray Box-CAF47.pdf'
import Report1810104 from '../../resources/2018 10 10-4 CAF48R Gray box.pdf'
import Report180418v1 from '../../resources/2018 04 18 CAF48R Vapor Test.pdf'
import CAF48R from '../../resources/CERTIFICATE OF COMPLIANCE CAF48R-4.pdf';


export default function () {
    const { fps551 } = useStaticQuery(
        graphql`query fps55 {
            fps551: file(relativePath: {eq: "products-pressurizers.png"}) {
                childImageSharp {
                    fluid(maxWidth: 700) {
                        ...GatsbyImageSharpFluid_tracedSVG
                    }
                    fixed(width: 650) {
                        ...GatsbyImageSharpFixed_tracedSVG
                    }
                }
            }
        }`
    );
    return <Layout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${data.title} | Clean Air Filter`}</title>
        </Helmet>
        <section>
            <h1><span className="tail"><span className="head">{data.content.section1.h1.head}</span> {data.content.section1.h1.tail}</span></h1>
            <p>{data.content.section1.p1}</p>
        </section>
        <section className="w-60">
            <h2>{data.content.section3.h2}</h2>
            <p>{data.content.section3.p1}</p>
            <ol dangerouslySetInnerHTML={{ __html: data.content.section3.ol }}></ol>
            <p>{data.content.section3.p2}</p>
            <p><a className="download-link" href={Report1810104} target="_blank">Test Report 181010-4</a></p>
            <p><a className="download-link" href={Report180418v1} target="_blank">Test Report 180418-V1</a></p>
            <p><a className="download-link" href={CAF48R} target="_blank">CAF48R Certificate</a></p>
        </section>
        <section className="w-60">
            <h2>{data.content.section2.h2}</h2>
            <p dangerouslySetInnerHTML={{ __html: data.content.section2.p1 }}></p>                    
            <ol dangerouslySetInnerHTML={{ __html: data.content.section2.ol }}></ol>
            <p>{data.content.section2.p2}</p>
            <a className="download-link" href={Report081113A} target="_blank">Test Report 081113A</a>
        </section>
        <section className="w-60">
            <h2>{data.content.section4.h2}</h2>
            <p dangerouslySetInnerHTML={{ __html: data.content.section4.p1 }}></p>   
            <ol dangerouslySetInnerHTML={{ __html: data.content.section4.ol }}></ol>
            <p dangerouslySetInnerHTML={{ __html: data.content.section4.p2 }}></p>
            <p><a className="download-link" href={Report1810081} target="_blank">Test Report 181008-1</a></p>
        </section>
        <section className="w-60">
            <h2>{data.content.section5.h2}</h2>
            <p dangerouslySetInnerHTML={{ __html: data.content.section5.table }}></p>
        </section>
    </Layout>;
}
